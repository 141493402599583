<template>
  <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showOverlay === 'vortestPicker'" class="w-full h-auto md:h-full fixed top-0 md:flex md:flex-row md:justify-center md:items-center md:inset-0 z-30 font-arial text-new-black">
      <div class="relative w-full md:w-5/12 md:min-w-100 h-screen min-h-screen md:min-h-0 md:h-auto md:max-h-80  md:overflow-y-auto md:bg-new-yellow md:rounded-t-3xl md:rounded-3xl md:p-5 pointer-events-auto scrollbar flex flex-col">

        <!-- Content -->
        <div class="w-full h-1/6" @click="hidePicker"></div>
        <div class="w-full h-5/6 bg-new-yellow rounded-t-3xl md:rounded-3xl p-5 flex flex-col">
          <!-- Fixed -->
          <div class="h-auto">
            <div class="text-xl" @click="hidePicker">schließen</div>
            <div class="mt-2 font-faro font-bold text-3xl">Offene Vortests</div>
          </div>
          <!-- Scrollable -->
          <div class="flex-grow overflow-y-scroll scrollbar">

            <div class="mt-3">Schließe alle Lernspots ab um die Prüfung zu absolvieren.</div>

            <div v-if="selectedExam" class="w-full md:auto bg-white rounded-xl shadow-new mt-5 border border-hi-lighgrey overflow-hidden">
              <div v-for="(vortest, index) in selectedExam?.attributes?.vortest" :key="vortest" class="w-full p-3 flex flex-row justify-between items-center font-arial cursor-pointer" @click="!vortestDone(getVortest(vortest)) ? goToLernspot(getVortest(vortest)) : ''"
              :class="{'border-b border-new-yellow' : index < selectedExam?.attributes?.vortest?.length - 1, 'bg-green-200' : vortestDone(getVortest(vortest)) }">
                <div class="flex-grow pr-2 flex flex-row justify-between items-center">
                  <div :class="{'opacity-50' : loadingLernpfad}">
                    <div class="text-lg font-bold">{{ getVortest(vortest)?.lernpfad }}</div>
                    <div class="text-lg">{{ getVortest(vortest)?.lernspot }}</div>
                  </div>
                  <checkSVG v-if="vortestDone(getVortest(vortest))" color="black" class="flex-shrink-0" />
                  <loadingSVG v-if="loadingLernpfad" class="flex-shrink-0 mr-5 text-new-black" />
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- Content -->


      </div>
    </div>
  </transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// SVG
import checkSVG from '../../../assets/svg/check.vue';
import loadingSVG from '../../../assets/svg/loading.vue';

export default {
  name: 'vortestPicker',
  mixins: [slideInOutBottom, iosVibrations],
  props: ['selectedExam', 'lernspots'],
  components: { checkSVG, loadingSVG },

  data() {
    return {
      loadingLernpfad: false,
    };
  },

  computed: {
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },
    lernpfadProgress() {
      return this.$store.state.profile.me.userData.data.attributes.lernpfadProgress;
    },
    selectedLernpfad() {
      return this.$store.state.learning.selectedLernpfad;
    },
  },

  watch: {
    selectedLernpfad() {
      console.log('changed');
      if (this.loadingLernpfad) {
        this.loadingLernpfad = false;
        this.$store.commit('showOverlay', null);
        this.$router.push({ name: 'lernspot' });
      }
    },
  },

  methods: {

    getVortest(vortest) {
      const lernspotIndex = this.lernspots.findIndex((lernspot) => lernspot.index === vortest.index);
      if (lernspotIndex !== -1) {
        return this.lernspots[lernspotIndex];
      }
    },

    vortestDone(vortest) {
      let done = false;
      this.lernpfadProgress.lernpfade.forEach((lernpfad) => {
        lernpfad.lernspots.forEach((lernspot) => {
          if (lernspot.id === vortest.id && lernspot.finished) {
            done = true;
          }
        });
      });
      return done;
    },

    goToLernspot(lernspot) {
      console.log(lernspot);
      this.iosVibrate(0);
      this.loadingLernpfad = true;
      this.$store.commit('setSelectedLernpfadId', lernspot.lernpfadId);
      this.$store.commit('setSelectedLernspotId', lernspot.id);
    },

    hidePicker() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', null);
    },
  },
};
</script>
