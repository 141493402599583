<template>
  <div>
    <!-- Components -->
    <vortestPicker :selectedExam="selectedExam" :lernspots="requiredLernspotIds" />
    <infoOverlay :text="`Möchtest Du die Prüfung jetzt starten? Du hast dafür insgesamt ${getExamTime(selectedExam)} Minuten Zeit.`" button="Prüfung starten" :index=2 @buttonClicked="confirmStartExam" />
    <infoOverlay :text="`Möchtest Du die Prüfung fortsetzen? Du hast noch ${getRemainingTime()} Minuten Zeit.`" button="Prüfung fortsetzen" :index=3 @buttonClicked="confirmOpenExam('exam')" />
    <infoOverlay text="Du kannst nicht mehr als eine Prüfung gleichzeitig absolvieren. Schließe die bereits gestartete Prüfung ab." :index=4 />
    <infoOverlay text="Du hast diese Prüfung bereits abgeschlossen. Möchtest Du dir Deine Antworten noch einmal anschauen?" button="Prüfung anschauen" :index=5 @buttonClicked="confirmOpenExam('review')" />

    <querySelectedLernpfad />
    <startExamMutation />
    <!-- Components -->

    <!-- Background -->
    <hafn v-if="appName === 'HAFN'" class="hidden md:block fixed bottom-5 right-5 h-95 w-auto opacity-5 z-000"/>

    <!-- Content -->
    <div class="md:pl-24 pt-14 text-new-black font-arial">
      <div class="py-5 md:px-5 md:pr-10" :class="{'pt-safe' : parseInt(sat) !== 0}">

        <!-- Heading -->
        <div class="px-5 mt-6">
          <div class="font-faro font-bold text-3xl">Testen</div>
          <span>Hier findest du alle freigeschalteten und absolvierten Klausuren.</span>
        </div>

        <!-- Slider -->
        <div ref="slider" class="mt-5 px-5 flex flex-row w-auto h-auto md:w-full overflow-auto scrollbar" :class="{'snap-mandatory' : mobile}"
          @mousedown.passive="slideDragStart"
          @mousemove.prevent="slideDrag"
          @mouseup.passive="slideDragEnd">
            <slideItemTraining v-for="(exam, index) in myAvailableExams" :key="exam" :heading="exam.attributes.Index" :deactivated="oneExamIsStarted && !isExamStarted(exam.id)"
            :text="getExamText(exam)"
            :single="index === 0 && myAvailableExams.length === 1"
            :timer="exam.upcoming"
            :startTime="exam?.start"
            :showButton="!exam.upcoming"
            :buttonText="checkIfTestsAreDone(exam.attributes.vortest) ? isExamStarted(exam.id)? 'Bereits gestartet' : 'Prüfung absolvieren' : 'Zum Vortest' " @buttonClicked="beginExam(exam)"
            :class="{
              'flex-shrink-0 ml-5 md:ml-0 mr-3 snap-start' : index === 0 && myAvailableExams.length > 1,
              'flex-shrink-0 mr-3 snap-start' : index > 0 && index < myAvailableExams.length - 1,
              'flex-shrink-0 snap-end' : index === myAvailableExams.length - 1 && myAvailableExams.length > 1,
              'border-3 border-red-500': isExamStarted(exam.id)
              }" />
        </div>

        <!-- Recent exams -->
        <div class="px-5 mt-5 md:w-1/3 md:min-w-100">
          <div class="font-faro font-bold text-lg">Zuletzt absolviert</div>
          <div class="flex-shrink-0 mt-0.5 flex flex-row text-new-midGrey overflow-y-scroll whitespace-nowrap scrollbar">
            <div v-for="filter in examFilter" :key=filter class="cursor-pointer mr-3 select-none" @click="currentFilter = filter">
              <div :class="{'text-new-black' : filter === currentFilter}">{{ filter }}</div>
            </div>
          </div>
          <div v-for="exam in myLimitedExams" :key="exam" class="mt-3 md:mt-2">
            <recentItem
              :loading="loading && selectedExamId === exam.id"
              :heading="exam?.pruefungen?.data?.attributes?.Index"
              :subheading="`${exam?.totalCorrect} von ${exam?.progress?.data.length} Punkten`"
              :status="exam?.passed ? 'Bestanden' : 'Nicht bestanden'"
              :showCircle="true"
              :done="getDone(exam?.progress?.data)"
              :total="exam?.progress?.data?.length"
              @click="openExam(exam)" />
          </div>
          <div v-if="showLimit < myRecentExams.length" class="w-full text-center p-5 cursor-pointer" @click="showMore()">Mehr anzeigen</div>
          <div v-if="myLimitedExams?.length === 0" class="w-full flex flex-col justify-center items-center mt-10 p-5 text-center">
            <div>Keine Prüfungen verfügbar</div>
            <div class="text-new-midGrey">Sieht aus, als hättest du noch keine Prüfung abgeschlossen.</div>
          </div>
        </div>

        <!-- Scrollspace Mobile -->
        <div class="mb-36 md:hidden"></div>

      </div>
    </div>
  </div>
</template>

<script>
// Mixins
import iosVibrations from '../../mixins/iosVibrations.vue';
import detectNotch from '../../mixins/detectNotch.vue';
import formatDate from '../../mixins/formatDate.vue';
import slideDrag from '../../mixins/slideDrag.vue';
import mobile from '../../mixins/mobile.vue';

// Components
import slideItemTraining from '../training/components/slideItemTraining.vue';
import recentItem from '../dashboard/components/recentItem.vue';
import vortestPicker from './components/vortestPicker.vue';
import infoOverlay from '../../symbols/infoOverlay.vue';

// Strapi
import querySelectedLernpfad from '../learning/strapi/querySelectedLernpfad.vue';
import startExamMutation from './strapi/startExamMutation.vue';

// SVG
import hafn from '../../assets/svg/hafn.vue';

export default {
  name: 'exam',
  mixins: [iosVibrations, detectNotch, formatDate, slideDrag, mobile],
  components: {
    hafn,
    slideItemTraining,
    recentItem,
    vortestPicker,
    querySelectedLernpfad,
    infoOverlay,
    startExamMutation,
  },

  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      loading: false,
      showLimit: 5,
      selectedExamId: null,
      oneExamIsStarted: false,
      startedExam: null,
      serverTime: null,
      selectedExam: null,
      requiredLernspotIds: [],
      currentFilter: 'Alle',
      examFilter: ['Alle', 'Bestanden', 'Nicht bestanden'],
    };
  },

  computed: {
    currentCourseID() {
      return this.$store.state?.settings.currentCourseID;
    },
    lernpfadProgress() {
      return this.$store.state.profile.me.userData.data.attributes.lernpfadProgress;
    },
    today() {
      return this.$store.state.profile.today;
    },
    todayPlusOneWeek() {
      const today = new Date();
      today.setDate(today.getDate() + 7);
      return today.toISOString();
    },
    queryMyExamsEx() {
      return this.$store.state.queryMyExamsEx;
    },
    queryVortestEx() {
      return this.$store.state.queryVortestEx;
    },
    myExams() {
      return this.$store.state.profile.myExams;
    },
    allRecentExams() {
      return this?.myExams?.userData?.data?.attributes?.examProgress;
    },
    allExams() {
      const exams = [];

      const classExams = this?.myExams?.klassen?.data;
      if (classExams) {
        classExams.forEach((klasse) => {
          const kursIndex = klasse?.attributes?.Zuordnung.findIndex((zuordnung) => zuordnung.Kurs);
          if (kursIndex !== -1 && klasse.attributes.Zuordnung[kursIndex].Kurs.data.id === this.currentCourseID) {
            const pruefungIndex = klasse?.attributes?.Zuordnung.findIndex((zuordnung) => zuordnung.pruefung);
            if (pruefungIndex !== -1) {
              const { pruefung } = klasse.attributes.Zuordnung[pruefungIndex];
              pruefung.forEach((exam) => {
                exams.push(exam?.pruefungen?.data);
              });
            }
          }
        });
      }

      const privateExams = this?.myExams?.pruefungen?.data;
      if (privateExams) {
        privateExams.forEach((exam) => {
          const examIndex = exams.findIndex((classExam) => classExam.id === exam.id);
          console.log(exam);
          if (examIndex === -1 && exam.attributes.publishedAt !== null) {
            exams.push(exam);
          }
        });
      }

      return exams;
    },
    myAvailableExams() {
      const exams = [];
      const classExams = this?.myExams?.klassen?.data;
      if (classExams) {
        classExams.forEach((klasse) => {
          const kursIndex = klasse?.attributes?.Zuordnung.findIndex((zuordnung) => zuordnung.Kurs);
          if (kursIndex !== -1 && klasse.attributes.Zuordnung[kursIndex].Kurs.data.id === this.currentCourseID) {
            const pruefungIndex = klasse?.attributes?.Zuordnung.findIndex((zuordnung) => zuordnung.pruefung);
            if (pruefungIndex !== -1) {
              // filter time
              const { pruefung } = klasse.attributes.Zuordnung[pruefungIndex];
              pruefung.forEach((exam) => {
                if (!exam.start && !exam.ende) {
                  // no date given
                  exams.push(exam?.pruefungen?.data);
                } else if (exam.start && exam.ende) {
                  // both dates given
                  if (exam.start && exam.start <= this.today && exam.ende && exam.ende >= this.today) {
                    exams.push(exam?.pruefungen?.data);
                    exams[exams.length - 1].end = exam.ende;
                  } else if (exam.start && exam.start <= this.todayPlusOneWeek && exam.ende && exam.ende >= this.today) {
                    // push upcoming
                    exams.push(exam?.pruefungen?.data);
                    exams[exams.length - 1].upcoming = true;
                    exams[exams.length - 1].start = exam.start;
                  }
                } else if ((exam.start && exam.start <= this.today) || (exam.ende && exam.ende >= this.today)) {
                  // only one date given
                  exams.push(exam?.pruefungen?.data);
                  if (exam.ende) exams[exams.length - 1].end = exam.ende;
                } else if ((exam.start && exam.start <= this.todayPlusOneWeek) || (exam.ende && exam.ende >= this.today)) {
                  // only one date given, push upcoming
                  exams.push(exam?.pruefungen?.data);
                  exams[exams.length - 1].upcoming = true;
                  exams[exams.length - 1].start = exam.start;
                }
              });
            }
          }
        });
      }

      const privateExams = this?.myExams?.pruefungen?.data;
      if (privateExams) {
        privateExams.forEach((exam) => {
          const examIndex = exams.findIndex((classExam) => classExam.id === exam.id);
          if (examIndex === -1 && exam.attributes.publishedAt !== null && exam.attributes?.kurs?.data?.id === this.currentCourseID) {
            exams.push(exam);
          }
        });
      }

      const unfinishedExams = [];

      exams.forEach((exam) => {
        const recentExamIndex = this.allRecentExams.findIndex((recentExam) => recentExam.pruefungen.data.id === exam.id);
        if (recentExamIndex === -1 || !this.allRecentExams[recentExamIndex].finished) {
          unfinishedExams.push(exam);
          this.getRequiredLernspotIds(exam.attributes.vortest);
        }
      });

      return unfinishedExams;
    },
    myRecentExams() {
      const recentExams = [];

      if (this.allRecentExams) {
        this.allRecentExams.forEach((exam) => {
          // eslint-disable-next-line eqeqeq
          if (exam.finished && exam.courseId == this.currentCourseID) recentExams.push(exam);
        });
      }

      if (this.currentFilter === 'Bestanden') {
        const finishedExams = [];
        recentExams.forEach((exam) => {
          if (exam.passed) {
            finishedExams.push(exam);
          }
        });
        return finishedExams;
      }

      if (this.currentFilter === 'Nicht bestanden') {
        const failedExams = [];
        recentExams.forEach((exam) => {
          if (!exam.passed) {
            failedExams.push(exam);
          }
        });
        return failedExams;
      }

      return recentExams;
    },
    myLimitedExams() {
      const limitedExams = this.myRecentExams.slice(0, this.showLimit);
      return limitedExams;
    },
    startExam() {
      return this.$store.state.startExam;
    },
    me() {
      return this.$store.state.profile.me;
    },
    userDataId() {
      return this.me?.userData?.data?.id;
    },
    queryServerTimeEx() {
      return this.$store.state.queryServerTimeEx;
    },
    myExamProgress() {
      return this.me.userData.data.attributes.examProgress;
    },
    queryMeEx() {
      return this.$store.state.queryMeEx;
    },
  },

  mounted() {
    this.queryMyExamsEx().then(() => {
      this.oneExamIsStarted = false;
    });


    this.queryServerTimeEx()
      .then((result) => {
        if (result.error) {
          console.log(result.error.message);
        } else {
          this.serverTime = result.data.getServerTime.serverTime;
        }
      });
  },

  beforeUnmount() {
    this.loading = false;
  },

  methods: {

    showMore() {
      this.showLimit += 5;
    },

    getDone(progress) {
      let count = 0;
      if (progress) {
        progress.forEach((item) => {
          if (item.result === 'correct' || item.result === 'success') count += 1;
        });
      }
      return count;
    },

    getExamText(exam) {
      if (!this.checkIfTestsAreDone(exam.attributes.vortest)) {
        return 'Du hast noch nicht alle Vortest absolviert.';
      }
      if (exam.upcoming) {
        return `Du kannst die Prüfung ab ${this.formatDate(exam.start)} absolvieren`;
      }
      if (this.isExamStarted(exam.id)) {
        return `Du hast die Prüfung bereits gestartet und musst sie innerhalb ${this.getRemainingTime()} Minuten beenden`;
      }
      if (exam?.end) {
        return `Du kannst diese Prüfung noch bis ${this.formatDate(exam.end)} absolvieren`;
      }
      return 'Du kannst diese Prüfung jetzt absolvieren';
    },

    getRemainingTime() {
      if (this.serverTime && this.startedExam && this.myAvailableExams) {
        const examIndex = this.myAvailableExams.findIndex((exam) => exam.id === this.startedExam.pruefungen.data.id);
        if (examIndex !== -1) {
          const serverTime = Date.parse(this.serverTime);
          const startedTime = Date.parse(this.startedExam.started);
          const minutes = this.getExamTime(this.myAvailableExams[examIndex]);
          const difference = serverTime - startedTime;
          const diffMins = Math.round(((difference % 86400000) % 3600000) / 60000); // minutes
          const remainingMinutes = minutes - diffMins;
          return remainingMinutes;
        }
      }
      return 0;
    },

    getExamTime(exam) {
      if (exam) {
        let timePerQuestion = 1.5;
        if (exam.attributes?.timePerQuestion) {
          timePerQuestion = exam.attributes.timePerQuestion;
        }
        return exam.attributes?.mc_fragens?.data?.length * timePerQuestion;
      }
    },

    isExamStarted(id) {
      const recentExamIndex = this.allRecentExams.findIndex((recentExam) => recentExam.pruefungen.data.id === id);
      if (recentExamIndex !== -1 && !this.allRecentExams[recentExamIndex].finished) {
        this.oneExamIsStarted = true;
        this.startedExam = this.allRecentExams[recentExamIndex];
        return true;
      }
      return false;
    },

    getRequiredLernspotIds(vortest) {
      if (vortest.length > 0) {
        const vortestIndices = [];
        const lernpfadIds = [];
        vortest.forEach((test) => {
          vortestIndices.push(test.index);
          lernpfadIds.push(test.lernpfade.data.id);
        });
        this.queryVortestEx({ lernpfadIds, vortestIndices }).then((result) => {
          if (result.error) {
            console.log(result.error.message);
          } else {
            result.data.lernpfades.data.forEach((lernpfad) => {
              if (lernpfad?.attributes?.lernspots?.length > 0) {
                lernpfad.attributes.lernspots.forEach((lernspot) => {
                  const item = {
                    lernpfadId: lernpfad.id,
                    lernpfad: lernpfad.attributes.Bezeichnung,
                    lernspot: lernspot.Bezeichnung,
                    index: lernspot.vortestIndex,
                    id: lernspot.id,
                  };
                  if (!this.requiredLernspotIds.includes(item)) this.requiredLernspotIds.push(item);
                });
              }
            });
          }
        });
      }
    },

    checkIfTestsAreDone(vortest) {
      if (vortest?.length > 0) {
        const neededMatches = vortest.length;
        let matches = 0;
        vortest.forEach((test) => {
          const testIndex = this.requiredLernspotIds.findIndex((item) => item.index === test.index);
          if (testIndex !== -1) {
            const requiredLernspotId = this.requiredLernspotIds[testIndex].id;
            if (this.lernpfadProgress?.lernpfade) {
              this.lernpfadProgress.lernpfade.forEach((progressLernpfad) => {
                progressLernpfad.lernspots.forEach((progressLernspot) => {
                  if (requiredLernspotId === progressLernspot.id && progressLernspot.finished) {
                    matches += 1;
                  }
                });
              });
            }
          }
        });
        return matches === neededMatches;
      }
      return true;
    },

    beginExam(exam) {
      this.iosVibrate(0);
      this.selectedExam = exam;
      if (this.oneExamIsStarted && !this.isExamStarted(exam.id) && !exam.upcoming) {
        this.$store.commit('toggleInfoOverlay', { value: true, index: 4 });
      } else
      if (this.checkIfTestsAreDone(exam.attributes.vortest)) {
        if (this.isExamStarted(exam.id) && !exam.upcoming) {
          this.$store.commit('toggleInfoOverlay', { value: true, index: 3 });
        } else if (!exam.upcoming) {
          this.$store.commit('toggleInfoOverlay', { value: true, index: 2 });
        }
      } else {
        this.$store.commit('showOverlay', 'vortestPicker');
      }
    },

    confirmStartExam() {
      this.startExam({ examId: this.selectedExam.id, userDataId: this.userDataId }).then((result) => {
        if (result.error) {
          console.log(result.error.message);
        } else {
          this.$store.commit('startExam', { exam: this.selectedExam, questions: result.data.startExam.data, mode: 'exam' });
          this.$router.push({ name: 'currentExam' });
        }
      });
    },

    confirmOpenExam(mode) {
      this.startExam({ examId: this.selectedExam.id, userDataId: this.userDataId }).then((result) => {
        this.loading = true;
        if (result.error) {
          console.log(result.error.message);
          this.loading = false;
        } else {
          this.queryMeEx().then((secondResult) => {
            if (secondResult.error) {
              console.log(secondResult.error.message);
              this.loading = false;
            } else {
              const examToContinueIndex = this.myExamProgress.findIndex((exam) => exam.pruefungen.data.id === this.selectedExam.id);
              if (examToContinueIndex !== -1) {
                const examToContinue = this.myExamProgress[examToContinueIndex];
                this.$store.commit('continueExam', {
                  exam: this.selectedExam,
                  questions: result.data.startExam.data,
                  currentProgess: examToContinue.progress,
                  mode,
                });
                this.$router.push({ name: 'currentExam' });
              }
            }
          });
        }
      });
    },

    openExam(exam) {
      this.iosVibrate(0);
      const examIndex = this.allExams.findIndex((availableExam) => availableExam.id === exam.pruefungen.data.id);
      this.selectedExamId = exam.id;
      this.selectedExam = this.allExams[examIndex];
      this.$store.commit('toggleInfoOverlay', { value: true, index: 5 });
    },
  },
};

</script>
